// React components
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

// Material-UI components
import App from './App';

// import components
import ScrollToTop from './ScrollToTop';

// fonts
import 'typeface-roboto';
import 'typeface-roboto-mono';

// CSS
import './index.css';

// JSS components
// import { ThemeProvider } from 'react-jss';

import { ThemeProvider } from "@material-ui/core/styles";

// theme
import theme from "./theme";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);
