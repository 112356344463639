// React components
import React, { Component } from 'react'
import { Link } from "react-router-dom"

// material-ui components
import Typography from "@material-ui/core/Typography";

// components
import ParallaxBlock from './ParallaxBlock';
import Button from './Button';
import NavigationDots from './NavigationDots';
import ScrollToTopButton from "./ScrollToTopButton";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";

// styles
import { useTheme, createUseStyles } from 'react-jss';
import useSharedStyles from './SharedStyles';

export default function AboutPage (props) {

  const theme = useTheme();
  // const classes = useStyles(theme);
  const sharedClasses = useSharedStyles(theme);

  function landing (header, subheader) {
    return (
      <div className={sharedClasses.parallaxBox}>
        <div className={sharedClasses.parallaxBoxContent}>
          <div className={sharedClasses.parallaxHeader}>
            {header}
          </div>
          <div className={sharedClasses.parallaxSubheader}>
            {subheader}
          </div>
        </div>
      </div>
    )
  }

  const sidebarData = [
    { id: "project", label: "The Project", level: 1 },
    { id: "about-me", label: "About Me", level: 1 },
    { id: "web-app", label: "The Web App", level: 1 },
  ];

  const content =
  <div>
    <div className={sharedClasses.section} id="project">
      <ParallaxBlock path="background-5.jpg" content={landing("The Project", "Read about the eulaw.app project")}/>
      <div className={sharedClasses.block}>
        <Typography variant="h6">
          Introduction
        </Typography>
        <Typography variant="body1">
          The <Link to="/" className={sharedClasses.link}>eulaw.app</Link> project provides access to six new databases on European Union (EU) law created by <a className={sharedClasses.link} href="http://www.joshuafjelstul.com">Joshua C. Fjelstul, Ph.D.</a> based on official data from the European Commission. It's the largest collection of research-ready quantitative data on EU law.
        </Typography>
        <Typography variant="body1">
          The 6 <span className={sharedClasses.link}>eulaw.app</span> databases include 60+ datasets that contain data on the corpus of EU law, the internal organization of the European Commission, Commission infringement cases, Commission state aid cases and state aid awards granted by member states, technical regulations proposed by member states, and more.
        </Typography>
        <Typography variant="body1">
          Data from the <span className={sharedClasses.link}>eulaw.app</span> databases has been used in studies published in leading political science and public administration journals including the <a className={sharedClasses.link} href="https://www.cambridge.org/core/journals/american-political-science-review/article/abs/politics-of-international-oversight-strategic-monitoring-and-legal-compliance-in-the-european-union/FEB58DC5F83D2BF718F5943F70451F47">American Political Science Review</a>, the <a className={sharedClasses.link} href="https://www.tandfonline.com/doi/full/10.1080/13501763.2021.1945130">Journal of European Public Policy</a>, and <a className={sharedClasses.link} href="https://journals.sagepub.com/doi/abs/10.1177/1465116519842947">European Union Politics</a>.
        </Typography>
        <Typography variant="body1">
          The broader <Link to="/" className={sharedClasses.link}>eulaw.app</Link> project provides an <span className={sharedClasses.code}>R</span> package for each database that allows researchers to access all of the data and documentation directly in <span className={sharedClasses.code}>R</span>. It also provides a REST API that researchers and developers can use to pull data from the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> database and an <span className={sharedClasses.code}>R</span> package, <span className={sharedClasses.code}>eulaw</span>, that provides an easy-to-use interface to the API, allowing users to access data from any of the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> databases using a single interface.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="Get Started" link={"/databases"}/>
          </div>
        </div>
      </div>
    </div>
    <div className={sharedClasses.section} id="about-me">
      <ParallaxBlock path="background-4.jpg" content={landing("About Me", "Check out my research and other projects")}/>
      <div className={sharedClasses.block}>
        <Typography variant="h6">
          Joshua C. Fjelstul, Ph.D.
        </Typography>
        <Typography variant="body1">
          I'm a political economist and data scientist. I'm currently a Post-Doctoral Research Fellow in the Department of Political Science and International Relations at the University of Geneva in Geneva, Switzerland. My fellowship is funded by the Research Council of Norway.
        </Typography>
        <Typography variant="body1">
          I received my Ph.D. in Political Science from Emory University in 2019. I also have an M.A. in Political Science from Emory, a B.A. in Government and History, with Special Honors in Government, from the College of Liberal Arts at The University of Texas at Austin, and a B.B.A. in the Canfield Business Honors Program (CBHP) from the McCombs School of Business at The University of Texas at Austin.
        </Typography>
        <Typography variant="body1">
          My research interests are at the intersection of international political economy, comparative politics, and international relations. I study the strategic interactions between domestic and international political institutions in political and legal disputes over policy implementation and compliance with international law. My empirical work focuses on the institutions of the European Union (EU), particularly the European Commission and the Court of Justice of the European Union (CJEU).
        </Typography>
        <Typography variant="body1">
          My methodological interests include analytical and computational game theory, agent-based modeling, structural estimators for game-theoretic equilibria, machine learning (especially deep neural networks for text data), quantitative text analysis, natural language processing, and causal inference for policy evaluation.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="Website" externalLink="http://www.joshuafjelstul.com"/>
          </div>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="LinkedIn" externalLink="https://www.linkedin.com/in/jfjelstul/"/>
          </div>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="GitHub" externalLink="https://github.com/jfjelstul"/>
          </div>
        </div>
      </div>
    </div>
    <div className={sharedClasses.section} id="web-app">
      <ParallaxBlock path="background-16.jpg" content={landing("The Web App", "Learn more about the eulaw.app web app")}/>
      <div id='web-app' className={sharedClasses.block}>
        <Typography variant="h6">
          Overview
        </Typography>
        <Typography variant="body1">
          The <Link to="/" className={sharedClasses.link}>eulaw.app</Link> web app was developed by <a className={sharedClasses.link} href="http://www.joshuafjelstul.com">Joshua C. Fjelstul, Ph.D.</a> It uses a variety of technologies (<span className={sharedClasses.code}>MariaDB</span>, <span className={sharedClasses.code}>Express.js</span>, <span className={sharedClasses.code}>React.js</span>, <span className={sharedClasses.code}>Node.js</span>, <span className={sharedClasses.code}>Nginx</span>, and <span className={sharedClasses.code}>D3</span>), languages (<span className={sharedClasses.code}>JavaScript</span>, <span className={sharedClasses.code}>JSX</span>, <span className={sharedClasses.code}>HTML</span>, <span className={sharedClasses.code}>CSS</span>, <span className={sharedClasses.code}>JSS</span>, and <span className={sharedClasses.code}>R</span>), and data formats (<span className={sharedClasses.code}>json</span>, <span className={sharedClasses.code}>CSV</span>, and <span className={sharedClasses.code}>RData</span>) to make data from the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> databases available to users.
        </Typography>
        <Typography variant="body1">
          The app has two main components: the frontend (the interactive part) and the backend (the database and API). I use modern, cutting-edge technologies in the web stack, including <a className={sharedClasses.link} href="https://mariadb.com">MariaDB</a> (for the database), <a className={sharedClasses.link} href="http://expressjs.com">Express.js</a> (for the API), <a className={sharedClasses.link} href="https://reactjs.org">React.js</a> (for the web app), and <a className={sharedClasses.link} href="https://nodejs.org/en/">Node.js</a> (for the web server).
        </Typography>
        <Typography variant="h6">
          The frontend
        </Typography>
        <Typography variant="body1">
          The frontend is the interactive, user-facing part of the web app. I built the frontend using a <span className={sharedClasses.code}>JavaScript</span> framework called <a className={sharedClasses.link} href="https://reactjs.org">React.js</a>, which was created by Facebook. React lets you build web apps from custom, reusable components written in <span className={sharedClasses.code}>JavaScript</span> and <span className={sharedClasses.code}>JSX</span>, which is mix of <span className={sharedClasses.code}>HTML</span> and <span className={sharedClasses.code}>JavaScript</span>.
        </Typography>
        <Typography variant="body1">
          All of the interactive components use a React-specific component library called <a className={sharedClasses.link} href="https://material-ui.com">Material-UI</a>, which is the best-available implementation of Google’s design language (a cohesive set of design principles), which is called <a className={sharedClasses.link} href="https://material.io">Material</a>. I use <span className={sharedClasses.code}>CSS</span> and <span className={sharedClasses.code}>JSS</span> to style the web app.
        </Typography>
        <Typography variant="h6">
          The backend
        </Typography>
        <Typography variant="body1">
          The backend is the part of the web app that provides data to the front end. The backend has two components: a <span className={sharedClasses.code}>MariaDB</span> database and a REST API. All of the code for the front end is available in the respository <span className={sharedClasses.code}>jfjelstul/eulaw-app-API</span> on <a className={sharedClasses.link} href="https://github.com/jfjelstul/eulaw-app-API">GitHub</a>. I use <a className={sharedClasses.link} href="https://mariadb.com">MariaDB</a> for the database, which is an open-source version of <span className={sharedClasses.code}>MySQL</span>. The API allows users to directly query the database. When a user uses the web app to request data from the database, the front end looks at the selections the user has made and constructs a call to the API. An API call is a <span className={sharedClasses.code}>URL</span> with optional parameters that specify what data the server should pull from the <a className={sharedClasses.link} href="https://mariadb.com">MariaDB</a> database.
        </Typography>
        <Typography variant="body1">
          I wrote the API in <span className={sharedClasses.code}>JavaScript</span> using <a className={sharedClasses.link} href="http://expressjs.com">Express.js</a>, which is a server framework for <a className={sharedClasses.link} href="https://nodejs.org/en/">Node.js</a> (an open-source <span className={sharedClasses.code}>JavaScript</span> runtime environment). The API looks at requests made by users (the URL it gets from the frontend), converts it to an <span className={sharedClasses.code}>SQL</span> query, and asks the <a className={sharedClasses.link} href="https://mariadb.com">MariaDB</a> database for the data.
        </Typography>
        <Typography variant="body1">
          The API gets the data in <span className={sharedClasses.code}>json</span> format, which is the standard way of sending data over an <span className={sharedClasses.code}>HTTP</span> connection, and sends that back to the frontend. The frontend then parses that <span className={sharedClasses.code}>json</span> data and converts it into an <span className={sharedClasses.code}>HTML</span> table that the user can interact with in the web app using a combination of <span className={sharedClasses.code}>JavaScript</span>, <span className={sharedClasses.code}>HTML</span>, and <span className={sharedClasses.code}>JSX</span>. The backend can also provide data in <span className={sharedClasses.code}>CSV</span> format, which the frontend will package up and save into the user's downloads folder. The API provides data to the <span className={sharedClasses.code}>R</span> package for the project, <span className={sharedClasses.code}>eulaw</span>, providing another way for researchers to interact with data. The <span className={sharedClasses.code}>R</span> package is available from the respository <span className={sharedClasses.code}>jfjelstul/eulaw</span> on <a className={sharedClasses.link} href="https://github.com/jfjelstul/eulaw">GitHub</a>.
        </Typography>
      </div>
    </div>
    <div id="end"/>
    <Footer/>
  </div>

  return (
    <div>
      {content}
    </div>
  );
};
