// React components
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

// utilities
// import { scroll } from "./Utilities";
import ScrollToTop from "./ScrollToTop";
import ArrowIcon from "@material-ui/icons/ArrowUpward";

// styles
import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

const useStyles = createUseStyles((theme) => ({
  toTop: {
    width: 50,
    height: 50,
    backgroundColor: theme.primaryColor,
    borderRadius: "50%",
    position: "fixed",
    right: 16 * 3,
    bottom: 16 * 3,
    transitionDuration: "0.25s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
  }
}));

export default function ScrollToTopButton (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 1000){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 1000){
      setShowScroll(false)
    }
  };
  window.addEventListener('scroll', checkScrollTop)

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return(
    <div className={classes.toTop} onClick={scrollTop} style={{opacity: showScroll ? 1 : 0}}>
      <ArrowIcon style={{color: "#FFFFFF"}}/>
    </div>
  );
};
