// React components
import React, { useEffect } from "react"
import { Link } from "react-router-dom"

// material-ui components
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

// API components
import Axios from "axios";

// components
import Button from "./Button";
import ParallaxBlock from "./ParallaxBlock";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";

// styles
import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

function GetDatabases (url) {
  const [data, setData] = React.useState(null);
  useEffect(() => {
    Axios.get(url)
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [url]);
  return data;
};

export default function DatabasesPage (props) {

  const sharedClasses = useSharedStyles();

  function landing (header, subheader) {
    return (
      <div className={sharedClasses.parallaxBox}>
        <div className={sharedClasses.parallaxBoxContent}>
          <div className={sharedClasses.parallaxHeader}>
            {header}
          </div>
          <div className={sharedClasses.parallaxSubheader}>
            {subheader}
          </div>
        </div>
      </div>
    )
  }

  function options (row) {
    return (
      <div>
        You can download data from the {row.abbreviation} Database and read the codebook on <Link to={"/"} className={sharedClasses.link}>eulaw.app</Link>. If you use <span className={sharedClasses.code}>R</span>, you can access data directly from <span className={sharedClasses.code}>R</span> using the <span className={sharedClasses.code}>{row.code}</span> package. You can also search and download data from the {row.abbreviation} Database using the <span className={sharedClasses.code}>eulaw</span> package, which provides an easy-to-use <span className={sharedClasses.code}>R</span> interface to the <Link to={"/"} className={sharedClasses.link}>eulaw.app</Link> API.
      </div>
    )
  };

  const databases = GetDatabases("https://www.api.eulaw.app/databases");

  const images = [
    "/background-10.jpg",
    "/background-18.jpg",
    "/background-7.jpg",
    "/background-8.jpg",
    "/background-15.jpg",
    "/background-19.jpg",
  ];

  const sidebarData = [
    { id: "databases", label: "The Databases" , level: 1},
    { id: "evoeu", label: "The Evolution of European Union Law (EvoEU) Database", level: 2 },
    { id: "ecio", label: "The European Commission Internal Organization (ECIO) Database", level: 2 },
    { id: "euip", label: "The European Union Infringement Procedure (EUIP) Database", level: 2 },
    { id: "eusa", label: "The European Union State Aid (EUSA) Database", level: 2 },
    { id: "eutr", label: "The European Union Technical Regulations (EUTR) Database", level: 2 },
    { id: "eums", label: "The European Union Member States (EUMS) Database", level: 2 },
  ];

  var content = <div style={{ height: 1000 }}/>;
  if (databases != null) {
    content =
    <div id="databases">
      <ParallaxBlock path="background-3.jpg" content={landing("The Databases", "Learn about the eulaw.app databases")}/>
      <div className={sharedClasses.cardPageHeader}>
        <div className={sharedClasses.block}>
          <Typography variant="h4">
            Overview of the databases
          </Typography>
          <Typography variant="body1">
            The <Link to="/" className={sharedClasses.link}>eulaw.app</Link> project provides access to 6 databases on European Union (EU) law created by <a className={sharedClasses.link} href="http://www.joshuafjelstul.com">Joshua C. Fjelstul, Ph.D.</a> based on official data from the European Commission. It's the largest collection of research-ready quantitative data on EU law.
          </Typography>
          <Typography variant="body1">
            The first database in the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> collection is the Evolution of European Union Law (EvoEU) database. This database, introduced in an <a className={sharedClasses.link} href="https://journals.sagepub.com/doi/abs/10.1177/1465116519842947">article published in European Union Politics</a> by <a className={sharedClasses.link} href="http://www.joshuafjelstul.com">Joshua C. Fjelstul</a>, tracks connections between EU primary law (the EU Treaties), EU secondary law (regulations, directives, and decisions), and EU case law (judgments and Advocate General opinions of Court of Justice of the European Union), national case law that applies EU law, and national law that implements EU law. It is the largeset dataset on EU law to date. It covers the entire history of the EU (1921-2015), contains over 365,000 legal documents, and records over 900,000 connections between them.
          </Typography>
          <Typography variant="body1">
            The second database in the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> collection is the European Commission Internal Organization (ECIO) database. This database includes data on Commissioners and Directorates-General to facilitate research on intra-Commission variation in enforcement behavior. It is designed to be fully compatible with the other databases.
          </Typography>
          <Typography variant="body1">
            The next three databases in the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> collection come from the European Union Compliance Project (EUCP), a collection of three new databases that cover the institutional procedures that the Commission uses to monitor and enforce member state compliance. The European Union Infringement Procedure (EUIP) Database includes the universe of infringement cases and Commission decisions in infringement cases (2002-2020). The European Union State Aid (EUSA) Database includes the universe of state aid cases, Commission decisions in state aid cases, and aid awarded by EU member states (1988-2020). The European Union Technical Regulations (EUTR) Database includes the universe of technical regulations notified by EU member states, and comments and opinions by member states and the Commission in response to notifications.
          </Typography>
          <Typography variant="body1">
            The final database in the <Link to="/" className={sharedClasses.link}>eulaw.app</Link> collection is the European Union Member State (EUMS) Database. This database includes a variety of useful information about EU member states, including information about variation in the legal obligations of member states due to opt-outs from the EU Treaties that member states have negotiated.
          </Typography>
          <Typography variant="body1">
            You can download data from each database and read the codebook for each dataset here on <Link to={"/"} className={sharedClasses.link}>eulaw.app</Link>. If you use <span className={sharedClasses.code}>R</span>, you can also access all of the data from any <Link to={"/"} className={sharedClasses.link}>eulaw.app</Link> database directly from <span className={sharedClasses.code}>R</span> using the dedicated <span className={sharedClasses.code}>R</span> package <Link to={"/resources"} className={sharedClasses.link}>for each database</Link>. You can also search for and download data using the <span className={sharedClasses.code}>eulaw</span> package, which provides an easy-to-use <span className={sharedClasses.code}>R</span> interface for the <Link to={"/"} className={sharedClasses.link}>eulaw.app</Link> API.
          </Typography>
        </div>
      </div>
      <div className={sharedClasses.cardPage}>
        {
          databases.map((row, i) => (
          <div key={i} id={row.code} className={sharedClasses.cardContainer}>
            <Card className={sharedClasses.card}>
              <CardMedia image={images[i]} style={{height: 300}} className={sharedClasses.cardImage}/>
              <div className={sharedClasses.cardContent}>
                <Typography variant="h3">
                  The {row.name_with_abbreviation}
                </Typography>
                <Typography variant="body1">
                  {row.long_description}
                </Typography>
                <div className={sharedClasses.blockRowCentered}>
                  <div className={sharedClasses.buttonContainerCentered}>
                    <Button label="Explore Data" link={"/databases/" + row.code}/>
                  </div>
                  <div className={sharedClasses.buttonContainerCentered}>
                    <Button label="R Package" link={"/resources#" + row.code}/>
                  </div>
                </div>
              </div>
            </Card>
          </div>))
        }
      </div>
      <Footer shadow={true}/>
    </div>
  }

  return (
    <div className={sharedClasses.page}>
      {/* <Sidebar data={sidebarData} /> */}
      {/* <Content content={content}/> */}
      {content}
    </div>
  );
};
