// React components
import React, { useEffect } from "react"
import { Link } from "react-router-dom"

// API components
import Axios from "axios";

// material-ui components
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// components
import DownloadButton from "./DownloadButton";
import Button from "./Button";
import ParallaxBlock from "./ParallaxBlock";
import NavigationDots from "./NavigationDots";
import ScrollToTopButton from "./ScrollToTopButton";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";

// styles
import useSharedStyles from "./SharedStyles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

function GetDatabases (url) {
  const [data, setData] = React.useState(null);
  useEffect(() => {
    Axios.get(url)
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [url]);
  return data;
};

export default function DatabasesPage (props) {

  const sharedClasses = useSharedStyles();

  function landing (header, subheader) {
    return (
      <div className={sharedClasses.parallaxBox}>
        <div className={sharedClasses.parallaxBoxContent}>
          <div className={sharedClasses.parallaxHeader}>
            {header}
          </div>
          <div className={sharedClasses.parallaxSubheader}>
            {subheader}
          </div>
        </div>
      </div>
    )
  }

  const databases = GetDatabases("https://www.api.eulaw.app/databases");

  const sidebarData = [
    { id: "r-packages", label: "R Packages", level: 1 },
    { id: "evoeu", label: "The evoeu package for the Evolution of European Union Law (EvoEU) Database", level: 2 },
    { id: "ecio", label: "The ecio package for the European Commission Internal Organization (ECIO) Database", level: 2 },
    { id: "euip", label: "The euip package for the European Union Infringement Procedure (EUIP) Database", level: 2 },
    { id: "eusa", label: "The eusa package for the European Union State Aid (EUSA) Database", level: 2 },
    { id: "eutr", label: "The eutr package for the European Union Technical Regulation (EUTR) Database", level: 2 },
    { id: "eums", label: "The eums package for the European Union Member States (EUMS) Database", level: 2 },
    { id: "eulaw-app-api", label: "The API", level: 1 },
    { id: "api-introduction", label: "An introduction to the eulaw.app API for data on European Union law", level: 2 },
    { id: "eulaw", label: "The eulaw package: An R interface to the eulaw.app API", level: 2 },
    { id: "eulaw-tutorial", label: "A tutorial for the eulaw package: Getting data on the EU infringement procedure", level: 2 },
  ];

  var packages = <div style={{ height: 1000 }}/>;
  if (databases != null) {
    packages =
    databases.map((row, i) => (
      <div>
        <div id={row.code} className={sharedClasses.block}>
          <Typography variant="h4">
            The {row.code} package for the {row.name_with_abbreviation}
          </Typography>
          <Typography varinat="body1">
            The <code className={sharedClasses.code}>{row.code}</code> package is an <code className={sharedClasses.code}>R</code> package for the {row.name_with_abbreviation}. {row.long_description}
          </Typography>
          <div className={sharedClasses.blockRowCentered}>
            <div className={sharedClasses.buttonContainerCentered}>
              <Button label="View on GitHub" externalLink={"https://github.com/jfjelstul/" + row.code}/>
            </div>
          </div>
          <Typography variant="h6">
            Installation
          </Typography>
          <Typography variant="body1">
            You can install the latest development version of the <code className={sharedClasses.code}>{row.code}</code> package from GitHub:
          </Typography>
          <pre className={sharedClasses.codeblock}>{
`# install.packages("devtools")
devtools::install_github("jfjelstul/` + row.code + `")`
          }</pre>
          <Typography variant="h6">
            Documentation
          </Typography>
          <Typography vairant="body1">
            Descriptions for each dataset and variable in the <code className={sharedClasses.code}>{row.code}</code> database are included as <code className={sharedClasses.code}>tibbles</code> in the <code className={sharedClasses.code}>R</code> package: <code className={sharedClasses.code}>{row.code}::dataset</code> and <code className={sharedClasses.code}>{row.code}::variables</code>. The same information is also available in the <code className={sharedClasses.code}>R</code> documentation for each dataset. You can see the documentation for a dataset by running <code className={sharedClasses.code}>?{row.code}::</code> followed by the name of the dataset.
          </Typography>
          <Typography variant="h6">
            Citation
          </Typography>
          <Typography variant="body1">
            If you use data from the <code className={sharedClasses.code}>{row.code}</code> package in a project or paper, please cite the package:
          </Typography>
          <Typography className={sharedClasses.quote}>
            Joshua Fjelstul (2021). {row.code}: The {row.name_with_abbreviation}. R package version 0.1.0.9000.
          </Typography>
          <Typography variant="body1">
            The <code className={sharedClasses.code}>BibTeX</code> entry for the package is:
          </Typography>
          <pre className={sharedClasses.codeblock}>{
`@Manual{,
  title = {` + row.code + `: The ` + row.name_with_abbreviation + `},
  author = {Joshua Fjelstul},
  year = {2021},
  note = {R package version 0.1.0.9000},
}`
          }</pre>
          <Typography variant="h6">
            Problems
          </Typography>
          <Typography variant="body1">
            If you notice an error in the data or a bug in the <code className={sharedClasses.code}>R</code> package, please report it on <a className={sharedClasses.link} href={"https://github.com/jfjelstul/" + row.code + "/issues"}>GitHub</a>.
          </Typography>
        </div>
        {/* { i != Object.keys(databases).length - 1 &&
          <Divider/>
        } */}
      </div>
    ));
  }

  const content =
  <div>
    <div id="r-packages">
      <ParallaxBlock path="background-9.jpg" content={landing("R Packages", "Learn about the R package for each database")}/>
      {packages}
    </div>
    <div id="eulaw-app-api">
      <ParallaxBlock path="background-6.jpg" content={landing("The API", "Learn about the eulaw.app API")}/>
      <div id="api-introduction" className={sharedClasses.block}>
        <Typography variant="h4">
          An introduction to the eulaw.app API for data on European Union law
        </Typography>
        <Typography variant="body1">
          The <Link className={sharedClasses.link} to={""}>eulaw.app</Link> API is a REST API that allows you to query data directly from the <Link className={sharedClasses.link} to={""}>eulaw.app</Link> database, which is a <span className={sharedClasses.code}>MariaDB</span> database. The best way to use the API is via the <span className={sharedClasses.code}>eulaw</span> package in <span className={sharedClasses.code}>R</span>. All of the code for the <Link className={sharedClasses.link} to={""}>eulaw.app</Link> API is available in the respository <span className={sharedClasses.code}>jfjelstul/eulaw-app-API</span> on <a className={sharedClasses.link}>GitHub</a>.
        </Typography>
        <Typography variant="body1">
          The API is written in <span className={sharedClasses.code}>JavaScript</span> using <a className={sharedClasses.link}>Express.js</a>, which is a server framework for <a className={sharedClasses.link}>Node.js</a> (an open-source <span className={sharedClasses.code}>JavaScript</span> runtime environment). The API looks at requests made by users, converts it to an <span className={sharedClasses.code}>SQL</span> query, and asks the <a className={sharedClasses.link}>MariaDB</a> database for the data.
        </Typography>
        <Typography variant="body1">
          The API gets the data in <span className={sharedClasses.code}>json</span> format, which is the standard way of sending data over an <span className={sharedClasses.code}>HTTP</span> connection, and sends that back to the user. Users can then parse that <span className={sharedClasses.code}>json</span> data. The data can be converted into an <span className={sharedClasses.code}>HTML</span> table that users can interact with in a web app using a combination of <span className={sharedClasses.code}>JavaScript</span>, <span className={sharedClasses.code}>HTML</span>, and <span className={sharedClasses.code}>JSX</span>. Users can also parse the data in <span className={sharedClasses.code}>R</span> into a <span className={sharedClasses.code}>data.frame</span> using the package <span className={sharedClasses.code}>jsonlite</span>.
        </Typography>
      </div>
      <div id="eulaw" className={sharedClasses.block}>
        <Typography variant="h4">
          The eulaw package: An R interface to the eulaw.app API
        </Typography>
        <Typography variant="body1">
          The <code className={sharedClasses.code}>eulaw</code> package is an <code className={sharedClasses.code}>R</code> package for accessing data from the <Link className={sharedClasses.link} to="/">eulaw.app</Link> API. This package provides an intuitive, easy-to-use <code className={sharedClasses.code}>R</code> interface for the <Link className={sharedClasses.link} to="/">eulaw.app</Link> API. This API provides access to all of the databases that are available via the <Link className={sharedClasses.link} to="/">eulaw.app</Link> API. Replication materials and documentation are available in the GitHub respository for each database.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="View on GitHub" externalLink={"https://github.com/jfjelstul/eulaw"}/>
          </div>
        </div>
        <Typography variant="h6">
          Installation
        </Typography>
        <Typography variant="body1">
          You can install the latest development version of the <code className={sharedClasses.code}>eulaw</code> package from GitHub:
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`# install.packages("devtools")
devtools::install_github("jfjelstul/eulaw")`
        }</pre>
        <Typography variant="h6">
          Citation
        </Typography>
        <Typography variant="body1">
          If you use data from the <code className={sharedClasses.code}>eulaw</code> package in a project or paper, please cite the package:
        </Typography>
        <div className={sharedClasses.quote}>
          Joshua Fjelstul (2021). eulaw: An R Interface to the eulaw.app API. R package version 0.1.0.9000.
        </div>
        <Typography variant="body1">
          The <code className={sharedClasses.code}>BibTeX</code> entry for the package is:
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`@Manual{,
  title = {eulaw: An R Interface to the eulaw.app API},
  author = {Joshua Fjelstul},
  year = {2021},
  note = {R package version 0.1.0.9000},
}`
        }</pre>
        <Typography variant="h6">
          Problems
        </Typography>
        <Typography variant="body1">
          If you notice an error in the data or a bug in the <code className={sharedClasses.code}>R</code> package, please report it on <a className={sharedClasses.link} href="https://github.com/jfjelstul/eulaw/issues">GitHub</a>.
        </Typography>
      </div>
      <div id="eulaw-tutorial" className={sharedClasses.block}>
        <Typography variant="h4">
          A tutorial for the eulaw package: Getting data on the EU infringement procedure
        </Typography>
        <Typography variant="body1">
          Suppose we want directed dyad-year data on decisions in infringement cases (i.e., the number of decisions opened by each Commission department against each member state per year). Further, suppose we want data only on letters of formal notice and reasoned opinions under Article 258 of the Treaty on the Functioning of the European Union (TFEU) since 2010. This data is available from the European Union Infringement Procedure (EUIP) Databse, which is part of the European Union Compliance Project (EUCP).
        </Typography>
        <Typography variant="body1">
          We can easily get exactly the data we're looking for right from <code className={sharedClasses.code}>R</code> in just a few easy steps using the <code className={sharedClasses.code}>eulaw</code> package, which is an <code className={sharedClasses.code}>R</code> interface for the <Link className={sharedClasses.link} to="/">eulaw.app</Link> API. This API provides access to a variety of research-ready databases, including the EUIP Database.
        </Typography>
        <Typography variant="h6">
          Looking up databases
        </Typography>
        <Typography variant="body1">
          First, let's double-check what datasets we have to work with. To see the databases that are available via the <Link className={sharedClasses.link} to="/">eulaw.app</Link> API, we can use the <code className={sharedClasses.code}>list_databases()</code> function. This function doesn't have any arguments.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`list_databases()
# Requesting data via the eulaw.app API...
# Response received...
# # A tibble: 6 x 2
#   database_id database
#         <int> <chr>
# 1           1 evoeu
# 2           2 ecio
# 3           3 euip
# 4           4 eusa
# 5           5 eutr
# 6           6 eums`
        }</pre>
        <Typography variant="body1">
          This function requests information via the evoeu.app API and returns a <code className={sharedClasses.code}>tibble</code> (see the <code className={sharedClasses.code}>tidyverse</code>) that lists the available databases. We're going to want the euip database. We could also use the <code className={sharedClasses.code}>describe_databases()</code> function, which also gives a description of each database.
        </Typography>
        <Typography variant="h6">
          Looking up datasets
        </Typography>
        <Typography variant="body1">
          Next, we need to pick the dataset in the euip database that has directed dyad-year data on decisions in infringement cases. To see the datasets that are available in the <code className={sharedClasses.code}>euip</code> database, we can use the <code className={sharedClasses.code}>list_datasets()</code> function. The function takes one argument, database, as given by <code className={sharedClasses.code}>list_databases()</code>.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`list_datasets(database = "euip")
# Requesting data via the eulaw.app API...
# Response received...
# # A tibble: 22 x 2
#    dataset_id dataset
#         <int> <chr>
#  1          1 cases
#  2          2 cases_ts
#  3          3 cases_ts_ct
#  4          4 cases_csts_ms
#  5          5 cases_csts_ms_ct
#  6          6 cases_csts_dp
#  7          7 cases_csts_dp_ct
#  8          8 cases_ddy
#  9          9 cases_ddy_ct
# 10         10 cases_net
# # … with 12 more rows`
        }</pre>
        <Typography variant="body1">
          To see the whole list, we can assign the output to an object, as in <code className={sharedClasses.code}>{"datasets <- list_datasets(database = \"euip\")"}</code>, and view it using <code className={sharedClasses.code}>View(datasets)</code>. We're looking for <code className={sharedClasses.code}>decisions_ddy</code>, which contains directed dyad-year data on decisions.
        </Typography>
        <Typography variant="body1">
          If we don't already know we're looking for the <code className={sharedClasses.code}>decisions_ddy</code> dataset, we can use the function <code className={sharedClasses.code}>describe_datasets()</code>, which provides a description of each dataset, to see what's available and find the right one.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`out <- describe_datasets(database = "euip")
# Requesting data via the eulaw.app API...
# Response received...
View(out)`
        }</pre>
        <Typography variant="h6">
          Checking the codebook
        </Typography>
        <Typography variant="body1">
          To double-check that the <code className={sharedClasses.code}>decisions_ddy</code> dataset has the information we're looking for, we can look at the codebook using the function <code className={sharedClasses.code}>describe_variables()</code>. This function has two required arguments, database and dataset. It returns a <code className={sharedClasses.code}>tibble</code>.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`out <- describe_variables(
  database = "euip",
  dataset = "decisions_ddy"
)
# Requesting data via the eulaw.app API...
# Response received...
View(out)`
        }</pre>
        <Typography variant="h6">
          Searching for data
        </Typography>
        <Typography variant="body1">
          Now that we know what database and dataset we need, and how to access the documentation, we're ready to download the data. We're specifically looking for directed dyad-year data on letters of formal notice and reasoned opinions under Article 258 TFEU, so we don't need to download the entire <code className={sharedClasses.code}>decisions_ddy</code> dataset, which also includes data on referrals to the Court and decisions under Article 260 TFEU. Instead of downloading the entire dataset, we can filter the data using the API and download just what we're looking for.
        </Typography>
        <Typography variant="body1">
          We can use the <code className={sharedClasses.code}>download_data()</code> function to download the data. This function takes two required arguments, database and dataset, and one optional argument, parameters. The parameters argument should be a list that specifies values for API parameters. API parameters correspond to variables in each dataset and let you filter the data. The <code className={sharedClasses.code}>download_data()</code> function will throw an error if we try use an invalid API parameter.
        </Typography>
        <Typography variant="h6">
          Looking up parameters
        </Typography>
        <Typography variant="body1">
          We can see the API parameters that are available for the <code className={sharedClasses.code}>decisions_ddy</code> dataset using the function <code className={sharedClasses.code}>list_parameters()</code>. This function has two required arguments, database and dataset.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`list_parameters(
  database = "euip",
  dataset = "decisions_ddy"
)
# Requesting data via the eulaw.app API...
# Response received...
# # A tibble: 5 x 2
#   parameter_id parameter
#          <int> <chr>
# 1            1 year_min
# 2            2 year_max
# 3            3 department_id
# 4            4 member_state_id
# 5            5 decision_stage_id`
        }</pre>
        <Typography variant="body1">
          We can see there are <code className={sharedClasses.code}>5</code> API parameters for the <code className={sharedClasses.code}>decisions_ddy</code> dataset. Generally, each API parameter corresponds to one variable in the dataset. There is an API parameter for all variables ending in <code className={sharedClasses.code}>_id</code>. The one exception to this rule is the year variable. If a dataset includes a year variable, there are two API parameters, <code className={sharedClasses.code}>year_min</code> and <code className={sharedClasses.code}>year_max</code>. This lets you specify a range.
        </Typography>
        <Typography variant="h6">
          Looking up parameter values
        </Typography>
        <Typography variant="body1">
          We want to use the <code className={sharedClasses.code}>decision_stage_id</code> parameter and the <code className={sharedClasses.code}>year_min</code> parameter, which will let us filter the data by decision stage and year. For the <code className={sharedClasses.code}>year_min</code> parameter, we just need to specify a year. For the parameter <code className={sharedClasses.code}>decision_stage_id</code>, we need to know what values to provide in order to get letters of formal notice and reasoned opinions under Article 258 TFEU. We can look up the corresponding variables, <code className={sharedClasses.code}>decision_stage_id</code> and decision_stage, in the codebook (as above). But we can easily see the unique values of <code className={sharedClasses.code}>decision_stage_id</code> using the function <code className={sharedClasses.code}>list_parameter_values()</code>. This function has two required arguments, database and parameter. API parameters often appear in multiple datasets within the same database, and are always coded the same way across datasets, so we don't need to specify which dataset we're interested in.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`list_parameter_values(
  database = "euip",
  parameter = "decision_stage_id"
)
# Requesting data via the eulaw.app API...
# Response received...
# # A tibble: 6 x 2
#   value label
#   <int> <chr>
# 1     1 Letter of formal notice (Article 258)
# 2     2 Reasoned opinion (Article 258)
# 3     3 Referral to the Court (Article 258)
# 4     4 Letter of formal notice (Article 260)
# 5     5 Reasoned opinion (Article 260)
# 6     6 Referral to the Court (Article 260)`
        }</pre>
        <Typography variant="body1">
          We can see from the output that letters of formal notice are coded <code className={sharedClasses.code}>1</code> and reasoned opinions are coded <code className={sharedClasses.code}>2</code>. When we specify the parameters argument in the <code className={sharedClasses.code}>download_data()</code> function, we need to provide a list where the name of each element is a valid API parameter. If we want to specify multiple values for a parameter, we can use a vector, as in <code className={sharedClasses.code}>decision_state_id = c(1, 2)</code>.
        </Typography>
        <Typography variant="h6">
          Downloading data
        </Typography>
        <Typography variant="body1">
          Now that we know how to use API parameters, we can use <code className={sharedClasses.code}>download_data()</code> to download just the data we're interested in.
        </Typography>
        <pre className={sharedClasses.codeblock}>{
`out <- download_data(
  database = "euip",
  dataset = "decisions_ddy",
  parameters = list(
    year_min = 2010,
    decision_stage_id = c(1, 2)
  )
)
# Requesting data via the eulaw.app API...
# Response received...
# Observations requested: 18300
# Downloading 10000 observations every 5 seconds...
# Total estimated time: 0.08 minutes (5 seconds)
# Batch 1 of 2 complete (observations 1 to 10000 of 18300)
# Batch 2 of 2 complete (observations 10001 to 18300 of 18300)
# Your download is complete
#
# If you use this database in a paper or project, please use the following citation:
#
# Joshua C. Fjelstul (2021). eulaw: An R Interface to the eulaw.app API. R package version 0.1.0.9000. https://github.com/jfjelstul/eulaw
View(out)`
        }</pre>
        <Typography variant="body1">
          The <code className={sharedClasses.code}>download_data()</code> function downloads the data in batches of <code className={sharedClasses.code}>10000</code> observations. The eulaw.app API has a rate limit, but this function automatically manages the rate limit for us. It will download <code className={sharedClasses.code}>1</code> batch approximately every <code className={sharedClasses.code}>5</code> seconds.
        </Typography>
        <Typography variant="body1">
          The function prints some useful information to the console while the data downloads. It tells us how many observations we have requested, how many batches it will take to download the data, and approximately how long it will take. It provides an update every time a batch is downloaded and counts down to the next batch. The function returns a <code className={sharedClasses.code}>tibble</code> that we can manipulate with <code className={sharedClasses.code}>dplyr</code> and <code className={sharedClasses.code}>tidyr</code>.
        </Typography>
        <Typography variant="body1">
          And that's it! We now have a research-ready dataset in our <code className={sharedClasses.code}>R</code> workspace.
        </Typography>
      </div>
    </div>
    <Footer/>
  </div>

  return (
    <div style={{width: "100vw"}}>
      <Sidebar data={sidebarData} />
      <Content content={content}/>
      {/* {content} */}
    </div>
  );
};
