// React components
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

// utilities
// import { scroll } from "./Utilities";
import ScrollToTop from "./ScrollToTop";

// styles
import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

const useStyles = createUseStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    right: 16 * 3,
    top: 50,
    transition: "opacity 0.25s",
  },
  navigationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    minHeight: 40,
    maxHeight: 40,
  },
  dotContainer: {
    width: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  navigationDot: {
    width: 15,
    height: 15,
    backgroundColor: theme.primaryColor,
    borderStyle: "solid",
    borderColor: theme.white,
    borderWidth: 2,
    borderRadius: "50%",
    transitionDuration: "0.25s",
  },
  navigationBox: {
    fontSize: theme.fontSizeSmall,
    paddingRight: 16,
    transitionDuration: "0.25s",
    opacity: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  navigationLabel: {
    color: "#FFFFFF",
    fontSize: theme.fontSizeVerySmall,
    textAlign: "center",
    fontWeight: 300,
    textAlign: "center",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: theme.primaryColor,
    // boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    // borderStyle: "solid",
    // borderColor: theme.white,
    // borderWidth: 2,
    borderRadius: 4,
    maxWidth: 150,
    opacity: 1,
  },
  arrow: {
    width: 0,
    height: 0,
    borderTop: "7px solid transparent",
    borderBottom: "7px solid transparent",
    borderLeft: "8px solid " + theme.primaryColor,
    opacity: 1,
  },
}));

function GetVisibility (id) {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    var element = document.getElementById(id);
    if(element != null) {
      var top = element.getBoundingClientRect().top + (window.innerHeight / 2) - 40;
      var bottom = element.getBoundingClientRect().bottom - (window.innerHeight / 2) - 40;
      setVisible(top < window.innerHeight && bottom > 0);
    }
  }, [scrollTop]);
  return visible;
}

function GetStartVisibility (id) {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    var element = document.getElementById(id);
    if(element != null) {
      var top = element.getBoundingClientRect().top;
      setVisible(top > 100);
    }
  }, [scrollTop]);
  return visible;
}

function GetEndVisibility (id) {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    var element = document.getElementById(id);
    if(element != null) {
      var top = element.getBoundingClientRect().top;
      setVisible(top < window.innerHeight);
    }
  }, [scrollTop]);
  return visible;
}

export default function DatabasePage (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { data, start, end } = props;

  const [dotHovered, setDotHovered] = React.useState(null);
  const [currentBlock, setCurrentBlock] = React.useState(null);

  const scrolling = React.useRef(null);

  function scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed(),
    onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener("scroll", onScroll)
        callback()
      }
    }
    window.addEventListener("scroll", onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: "smooth"
    })
  }

  function scroll (id, offset) {
    scrolling.current = true;
    setDotHovered(null);
    const yOffset = offset;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    scrollTo(y, () => {
      scrolling.current = false;
      setDotHovered(id);
    });
  };

  const active1 = !GetEndVisibility(end);
  const active2 = !GetStartVisibility(start);

  return(
    <div className={classes.container} style={{ opacity: active1 && active2 ? 1 : 0 }}>
      <div>
        {data.map((dot, i) => (
          <div key={i} className={classes.navigationContainer}>
            <span className={classes.navigationBox} style={{ opacity: dotHovered == dot.id ? 1 : 0}}>
              <div className={classes.navigationLabel}>
                {dot.label}
              </div>
              <div className={classes.arrow}/>
            </span>
            <div className={classes.dotContainer}>
              <div
                className={classes.navigationDot}
                style={{
                  opacity: GetVisibility(dot.id) && !scrolling.current ? 1 : 0.3,
                }}
                onClick={() => scroll(dot.id, -100)}
                onMouseEnter={() => setDotHovered(dot.id)}
                onMouseLeave={() => setDotHovered("")}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
