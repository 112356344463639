// React components
import React, { Component } from 'react'
import { Link } from "react-router-dom"

// material-ui components
import Typography from "@material-ui/core/Typography";

// components
import ParallaxBlock from './ParallaxBlock'
import Button from './Button'
import Footer from "./Footer";

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from "./SharedStyles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  logoText: {
    fontSize: 35,
    fontWeight: 700,
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: 1.5,
    width: "50%",
  },
}));

export default function HomePage (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const landing =
  <div className={sharedClasses.parallaxBox}>
    <div className={sharedClasses.parallaxBoxContent}>
      <div className={sharedClasses.parallaxHeader}>
        Welcome!
      </div>
      <div className={sharedClasses.parallaxSubheader}>
        A collection of quantitative data on European Union law
      </div>
    </div>
  </div>

  const image = <img className={classes.logoImage} src="/logo-white-large.png"/>

  return (
    <div>
      <ParallaxBlock height={`calc(100vh - ${75}px)`} path="/background-2.jpg" content={landing} image={image}/>
      <div className={sharedClasses.block}>
        <Typography variant="h4">
         Getting started
        </Typography>
        <Typography variant="body1">
          The <Link to="/" className={sharedClasses.link}>eulaw.app</Link> project provides access to six new databases on European Union (EU) law created by <a className={sharedClasses.link} href="http://www.joshuafjelstul.com">Joshua C. Fjelstul, Ph.D.</a> based on official data from the European Commission. It's the largest collection of research-ready quantitative data on EU law.
        </Typography>
        <Typography variant="body1">
          The six <span className={sharedClasses.link}>eulaw.app</span> databases include 60+ datasets that contain data on the corpus of EU law, the internal organization of the European Commission, Commission infringement cases, Commission state aid cases and state aid awards granted by member states, technical regulations proposed by member states, and more.
        </Typography>
        <Typography variant="body1">
          Data from the <span className={sharedClasses.link}>eulaw.app</span> databases has been used in studies published in leading political science and public administration journals including the <a className={sharedClasses.link} href="https://www.cambridge.org/core/journals/american-political-science-review/article/abs/politics-of-international-oversight-strategic-monitoring-and-legal-compliance-in-the-european-union/FEB58DC5F83D2BF718F5943F70451F47">American Political Science Review</a>, the <a className={sharedClasses.link} href="https://www.tandfonline.com/doi/full/10.1080/13501763.2021.1945130">Journal of European Public Policy</a>, and <a className={sharedClasses.link} href="https://journals.sagepub.com/doi/abs/10.1177/1465116519842947">European Union Politics</a>.
        </Typography>
        {/* <Typography variant="body1">
          You can explore and download data right from <span className={sharedClasses.linkWhite}>eulaw.app</span>. You can also read the codebook for each dataset and vignettes about the relevant institutional procedures to help you better understand the data.
        </Typography> */}
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <div className={sharedClasses.container}>
              <Button label="Learn More" link="/databases"/>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
