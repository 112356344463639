import React, { Component } from 'react'
import { useState } from 'react';
import { Parallax, Background } from "react-parallax"
import Typography from '@material-ui/core/Typography'

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from './SharedStyles';

export default function ParallaxBlock (props) {

  const sharedClasses = useSharedStyles();

  var { height, path, content, image } = props;

  if (image !== undefined) {
    image =
    <div className={sharedClasses.parallaxLogo}>
      {image}
    </div>
  }

  return(
    <Parallax
      blur={0}
      strength={300}
      bgImage={path}
      bgImageStyle={{ opacity: 0.8, width: "100%", height: "130%", objectFit: "cover" }}
    >
      <div className={sharedClasses.parallax} style={{height: height}}>
        {image}
        <div className={sharedClasses.parallaxCaption}>© European Union</div>
        {content}
      </div>
    </Parallax>
  )
}
