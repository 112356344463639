// react components
import React, { useEffect } from "react"
import { HashLink as Link } from "react-router-hash-link";

// api components
import Axios from "axios";

// material-ui components
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";

// components
import Button from "./Button";
import ParallaxBlock from "./ParallaxBlock";
import NavigationDots from "./NavigationDots";
import ScrollToTopButton from "./ScrollToTopButton";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";
import { scroll }  from "./Utilities";

// styles
import useSharedStyles from "./SharedStyles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      zIndex: 1,
      flexShrink: 0,
    },
  },
  drawerContainer: {
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 110,
    },
  },
  appBar: {
    position: "fixed",
    opacity: 0,
    height: 100,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up('md')]: {
      width: "100%",
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    position: "fixed",
    width: 100,
    height: 100,
    color: "#FFFFFF",
    zIndex: theme.zIndex.drawer + 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#F8F8F8",
    border: "none",
  },
  content: {
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100vw - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
}));

export default function DatabasesPage (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarData = [
    { id: "publications", label: "Publications" , level: 1},
    { id: "publication-1", label: "The Politics of International Oversight: Strategic Monitoring and Legal Compliance in the European Union" , level: 2},
    { id: "publication-2", label: "The Evolution of European Union Law: A New Dataset on the Acquis Communautaire" , level: 2},
    { id: "publication-3", label: "Improving the Efficiency of Pretrial Bargaining in Disputes over Noncompliance with International Law: Encouraging Evidence from the European Union" , level: 2},
    { id: "working-papers", label: "Working Papers", level: 1 },
    { id: "paper-1", label: "Noncompliance in the European Union: Institutional Procedures and Strategic Enforcement Behavior" , level: 2},
    { id: "paper-2", label: "Policy Implementation, Noncompliance, and the Judicial Impact of Courts" , level: 2},
    { id: "paper-3", label: "Do Administrative Deficits Cause Noncompliance with International Law? Evidence from the European Union" , level: 2},
  ];

  function landing (header, subheader) {
    return (
      <div className={sharedClasses.parallaxBox}>
        <div className={sharedClasses.parallaxBoxContent}>
          <div className={sharedClasses.parallaxHeader}>
            {header}
          </div>
          <div className={sharedClasses.parallaxSubheader}>
            {subheader}
          </div>
        </div>
      </div>
    )
  }

  const content =
  <div>
    <div id="publications" className={sharedClasses.section}>
      <ParallaxBlock path="background-11.jpg" content={landing("Publications", "See publications that use eulaw.app data")}/>
      <div id="publication-1" className={sharedClasses.block}>
        <Typography variant="h4">
          The Politics of International Oversight: Strategic Monitoring and Legal Compliance in the European Union
        </Typography>
        <Typography variant="h5">
          Joshua C. Fjelstul and Clifford Carrubba
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          States often violate international agreements, both accidentally and intentionally. To process complaints efficiently, states can create formal, pre-trial procedures in which governments can negotiate with litigants before a case ever goes to court. If disputes are resolved during pre-trial negotiations, it can be very difficult to tell what has happened. Are governments coming into compliance? If so, are they only doing so when they have accidentally committed a violation, or even when they are intentionally resisting? Or are challenges simply being dropped? This paper presents a formal model to address these questions. We develop our theory in the context of the European Union (EU). To test our model, we collect a new data set of over 13,000 Commission infringement cases against EU member states (2003-2013). Our results suggest that accidental and intentional noncompliance both occur, but that intentional noncompliance is more common in the EU. We find that the Commission is an effective, if imperfect, monitor and enforcer of international law. The Commission can correct intentional noncompliance, but not always. It strategically drops cases that it believes it is unlikely to win.
        </Typography>
        <Typography variant="h6">
          Citation
        </Typography>
        <Typography variant="body1">
          Fjelstul, Joshua C., and Clifford J. Carrubba. 2018. "The Politics of International Oversight: Strategic Monitoring and Legal Compliance in the European Union." <span className={sharedClasses.bold}>American Political Science Review</span> 112(3):429-445.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="View Paper" externalLink="https://www.cambridge.org/core/journals/american-political-science-review/article/abs/politics-of-international-oversight-strategic-monitoring-and-legal-compliance-in-the-european-union/FEB58DC5F83D2BF718F5943F70451F47"/>
          </div>
        </div>
      </div>
      <div id="publication-2" className={sharedClasses.block}>
        <Typography variant="h4">
          The Evolution of European Union Law: A New Data Set on the Acquis Communautaire
        </Typography>
        <Typography variant="h5">
          Joshua C. Fjelstul
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          The European Union legal system is one of the most complex and sophisticated in the world. This article models the Acquis Communautaire (i.e. the corpus of European Union law) as a network and introduces the Evolution of European Union Law data set, which tracks connections between European Union primary law, European Union secondary law, European Union case law, national case law that applies European Union law, and national law that implements European Union law. It is the largest, most comprehensive data set on European Union law to date. It covers the entire history of the European Union (1951–2015), contains over 365,000 documents, and records over 900,000 connections between them. Legislative and judicial scholars can use this data set to study legislative override of the Court of Justice of the European Union, the implementation of European Union law, and other important topics. As an illustration, I use the data set to provide empirical evidence consistent with legislative override.
        </Typography>
        <Typography variant="h6">
          Citation
        </Typography>
        <Typography variant="body1">
          Fjelstul, Joshua C. 2019. "The Evolution of European Union Law: A New Data Set on the Acquis Communautaire." <span className={sharedClasses.bold}>European Union Politics</span> 20(4):670-691.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="View Paper" externalLink="https://journals.sagepub.com/doi/abs/10.1177/1465116519842947"/>
          </div>
        </div>
      </div>
      <div id="publication-3" className={sharedClasses.block}>
        <Typography variant="h4">
          Improving the Efficiency of Pretrial Bargaining in Disputes over Noncompliance with International Law: Encouraging Evidence from the European Union
        </Typography>
        <Typography variant="h5">
          Sivaram Cheruvu and Joshua C. Fjelstul
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          International institutions can create pretrial bargaining procedures to resolve disputes over noncompliance with international law. In this article, we consider how international institutions can improve the efficiency of pretrial bargaining, thereby reducing the compliance deficit. We use a quasi-experimental research design and difference-in-differences (DD) estimators to analyze the effectiveness of a recent policy initiative in the European Union (EU) — called EU Pilot — that was designed to improve the efficiency of pretrial bargaining in the EU. By evaluating the effectiveness of EU Pilot, we provide new evidence of a proposed management school solution to noncompliance — proper rule interpretation. By preventing accidental noncompliance, EU Pilot allows the Commission and member states to more quickly process cases involving intentional noncompliance, improving the efficiency of pretrial bargaining in the EU.
        </Typography>
        <Typography variant="h6">
          Citation
        </Typography>
        <Typography variant="body1">
          Cheruvu, Sivaram, and Joshua C. Fjelstul. 2021. "Improving the Efficiency of Pretrial Bargaining in Disputes over Noncompliance with International Law: Encouraging Evidence from the European Union." <span className={sharedClasses.bold}>Journal of European Public Policy</span>.
        </Typography>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="View Paper" externalLink="https://www.tandfonline.com/doi/full/10.1080/13501763.2021.1945130"/>
          </div>
        </div>
      </div>
    </div>
    <div className={sharedClasses.section} id="working-papers">
      <ParallaxBlock path="background-13.jpg" content={landing("Working Papers", "See working papers that use eulaw.app data")}/>
      <div id="paper-1" className={sharedClasses.block}>
        <Typography variant="h4">
          Noncompliance in the European Union: Institutional Procedures and Strategic Enforcement Behavior
        </Typography>
        <Typography variant="h5">
          Joshua C. Fjelstul
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          The European Union (EU) has a central monitoring system that empowers the European Commission to monitor member state compliance with EU law. This paper introduces the European Union Compliance Project (EUCP), a collection of three original databases — the European Union Infringement Procedure (EUIP) Database, the European Union State Aid (EUSA) Database, and the European Union Technical Regulation (EUTR) Database — that cover the major institutional procedures that the Commission uses to monitor member state compliance. To illustrate how researchers can use these databases, I investigate the behavior of the Commission in response to the European Sovereign Debt Crisis. Building on recent research on the strategic behavior of monitoring institutions and international courts, and using Bayesian multi-level generalized linear models, I provide evidence that the Commission has been strategically lenient towards embattled members of the European Economic and Monetary Union (EMU).
        </Typography>
        {/* <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <DownloadButton label="Download Paper" filePath={""} fileName={""}/>
          </div>
        </div> */}
      </div>
      <div id="paper-2" className={sharedClasses.block}>
        <Typography variant="h4">
          Policy Implementation, Noncompliance, and the Judicial Impact of Courts
        </Typography>
        <Typography variant="h5">
          Joshua C. Fjelstul
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          Policy-makers depend on government agencies to implement laws, and courts adjudicate disputes over incorrect implementation. This paper analyzes how the politics of adjudication affect the implementation of policy. Under what conditions do government agencies more closely implement laws, and when do courts deter incorrect implementation? I model the strategic interaction between an implementing actor, a plaintiff, and a court over the implementation of a law. In equilibrium, implementing actors make concessions by implementing more complaint policies in order to avoid litigation. I find that courts are most effective at deterring noncompliance for intermediate levels of preference divergence between policy-makers and implementing actors. I test the observable implication of the model in the context of the European Union (EU) using a novel dataset of implementation opportunities and noncompliance cases.
        </Typography>
        {/* <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <DownloadButton label="Download Paper" filePath={""} fileName={""}/>
          </div>
        </div> */}
      </div>
      <div id="paper-3" className={sharedClasses.block}>
        <Typography variant="h4">
          Do Administrative Deficits Cause Noncompliance with International Law? Evidence from the European Union
        </Typography>
        <Typography variant="h5">
          Sivaram Cheruvu and Joshua C. Fjelstul
        </Typography>
        <Typography variant="h6">
          Abstract
        </Typography>
        <Typography variant="body1">
          What are the causes of noncompliance with international law? A rich scholarship argues that limitations in administrative capacity are one of these causes. We provide new quantitative evidence of this relationship. Employing a dataset of infringement cases the European Commission launched against member states and leveraging member state qualification in the FIFA World Cup and UEFA Euros as an exogenous source of variation in their administrative capacity, we use a difference-in-differences design to provide evidence that administrative deficits cause a substantial delay in Commission infringement cases, which worsens the EU’s compliance deficit.
        </Typography>
        {/* <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <DownloadButton label="Download Paper" filePath={""} fileName={""}/>
          </div>
        </div> */}
      </div>
    </div>
    <Footer/>
  </div>

  return (
    <div>
      <Sidebar data={sidebarData} />
      <Content content={content}/>
      {/* {content} */}
    </div>
  );
};
