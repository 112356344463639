// React components
import React from "react";
import { Link } from "react-router-dom";

// styles
// import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

export default function DownloadButton (props) {

  const { label, onClick, link, externalLink, fileName, filePath, disabled } = props;

  const sharedClasses = useSharedStyles();

  var button = null;

  var className = sharedClasses.button;
  if (disabled === true) {
    className = sharedClasses.buttonDisabled;
  }

  if (onClick !== undefined) {
    button = <div className={className} onClick={onClick}>{label}</div>;
  } else {
    button = <div className={className} >{label}</div>;
  }

  if (link !== undefined) {
    button =
    <Link to={link} style={{textDecoration: "none"}}>
      <div className={className}>{label}</div>
    </Link>;
  }

  if (externalLink !== undefined) {
    button = <a className={className} href={externalLink} style={{textDecoration: "none"}}>{label}</a>;
  }

  if (fileName !== undefined && filePath !== undefined) {
    button = <a className={className} href={filePath} download={fileName}>{label}</a>
  }

  return (
    button
  );
};
