// React components
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

// API components
import Axios from "axios";

// Material-UI components
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

// components
import Button from "./Button";
import DownloadButton from "./DownloadButton";
import ParallaxBlock from "./ParallaxBlock";
import DataCodebook from "./DataCodebook";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";

// utilities
import { scroll, link, inlineTitle, bold, code } from "./Utilities";

// styles
import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

const useStyles = createUseStyles((theme) => ({
  dataDrawer: {
    boxShadow: theme.shadow + "!important",
  },
  contactDrawer: {
    boxShadow: theme.shadow + "!important",
  },
  codebookDrawer: {
    maxHeight: "90vh!important",
    minHeight: "90vh!important",
    boxShadow: theme.shadow + "!important",
  },
  apiCodebookDrawer: {
    maxHeight: "90vh!important",
    boxShadow: theme.shadow + "!important",
  },
  closeButtonTop: {
    textAlign: "right",
    padding: 32,
    marginBottom: -100
    // position: "fixed",
    // top: 75,
    // right: 75,
  },
  // closeButtonBottom: {
  //   position: "absolute",
  //   top: 75,
  //   right: 75,
  // },
  chipBar: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    paddingLeft: 16,
    paddingTop: 32,
  },
  chipContainer: {
    paddingBottom: 8,
    paddingRight: 8,
  },
  chip: {
    fontWeight: 300,
    fontFamily: "Roboto Mono",
  },
}));

function GetDatabases (url) {
  const [data, setData] = React.useState(null);
  useEffect(() => {
    Axios.get(url)
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [url]);
  return data;
};

function GetDatasets (url) {
  const [data, setData] = React.useState(null);
  useEffect(() => {
    Axios.get(url)
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [url]);
  return data;
};

export default function DatasetsPage (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { database, image } = props;

  const [dataDrawerIsOpen, setDataDrawerIsOpen] = React.useState(false);
  const [codebookDrawerIsOpen, setCodebookDrawerIsOpen] = React.useState(false);
  const [contactDrawerIsOpen, setContactDrawerIsOpen] = React.useState(false);
  const [apiCodebookDrawerIsOpen, setApiCodebookDrawerIsOpen] = React.useState(false);

  const [dataset, setDataset] = React.useState("");
  const [route, setRoute] = React.useState("");

  const [rDataPath, setRDataPath] = React.useState("");
  const [csvDataPath, setCsvDataPath] = React.useState("");
  const [pdfCodebookPath, setPdfCodebookPath] = React.useState("");
  const [csvCodebookPath, setCsvCodebookPath] = React.useState("");

  const images = [
    "/detail-1.jpg",
    "/detail-2.jpg",
    "/detail-3.jpg",
    "/detail-4.jpg",
    "/detail-5.jpg",
    "/detail-6.jpg",
    "/detail-7.jpg",
    "/detail-8.jpg",
    "/detail-9.jpg",
    "/detail-10.jpg",
    "/detail-11.jpg",
    "/detail-12.jpg",
    "/detail-13.jpg",
    "/detail-14.jpg",
    "/detail-15.jpg",
  ];

  const handleDataDrawer = (dataset, rDataPath, csvDataPath) => {
    setDataset(dataset);
    setRDataPath(rDataPath);
    setCsvDataPath(csvDataPath);
    setDataDrawerIsOpen(true);
    console.log(rDataPath)
    console.log(csvDataPath)
  };

  const handleCodebookDrawer = (dataset, pdfCodebookPath, csvCodebookPath) => {
    setDataset(dataset);
    setPdfCodebookPath(pdfCodebookPath);
    setCsvCodebookPath(csvCodebookPath);
    setCodebookDrawerIsOpen(true);
    console.log(pdfCodebookPath)
    console.log(csvCodebookPath)
  };

  const handleApiCodebookDrawer = (dataset, route) => {
    setDataset(dataset);
    setRoute(route);
    setApiCodebookDrawerIsOpen(true);
  };

  const handleContactDrawer = (dataset) => {
    setDataset(dataset);
    setContactDrawerIsOpen(true);
  };

  function landing (header, subheader) {
    return (
      <div className={sharedClasses.parallaxBox}>
        <div className={sharedClasses.parallaxBoxContent}>
          <div className={sharedClasses.parallaxHeader}>
            {header}
          </div>
          <div className={sharedClasses.parallaxSubheader}>
            {subheader}
          </div>
        </div>
      </div>
    )
  }

  // const downloadCodebook = (url, fileName) => {
  //   url = url + "&download=1";
  //   console.log(url)
  //   Axios.get(url)
  //   .then(response => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // };

  var databaseData = GetDatabases("https://www.api.eulaw.app/databases?code=" + database);
  if (databaseData !== null) {
    var databaseData = databaseData[0];
    // databaseData = { id: "", code: "", abbreviation: "", name: "", name_with_abbreviation: "", short_description: "", long_description: "" };
  }

  const datasetsData = GetDatasets("https://www.api.eulaw.app/databases/" + database + "/datasets");

  var sidebarData = [];
  var row = { id: "overview", label: "Overview", level: 1 };
  sidebarData.push(row);
  if (datasetsData != null) {
    for (var i in datasetsData) {
      var row = { id: datasetsData[i].dataset, label: datasetsData[i].label, level: 2 }
      sidebarData.push(row);
    }
  }

  var dataDrawer = null;
  if (datasetsData !== null && databaseData !== null) {
    dataDrawer =
    <Drawer
      variant="temporary"
      anchor={"top"}
      open={dataDrawerIsOpen}
      elevation={0}
      classes={{paper: classes.dataDrawer}}
      transitionDuration={500}
      onClose={() => setDataDrawerIsOpen(false)}
      BackdropProps={{
        className: sharedClasses.drawerBackdrop
      }}
      PaperProps={{
        style: {
          maxHeight: "90vh",
        }
      }}
    >
      <div className={classes.closeButtonTop}>
        <IconButton className={classes.icon} disableRipple={true} onClick={() => setDataDrawerIsOpen(false)}>
          <CloseIcon/>
        </IconButton>
      </div>
      <div className={sharedClasses.block} style={{backgroundColor: "white"}}>
        <Typography variant="h4">
          Citing the data
        </Typography>
        <Typography variant="body1">
          If you use data from the {code(dataset, sharedClasses)} dataset from the {databaseData.name_with_abbreviation} in a paper or project, please cite the database using the following citation:
        </Typography>
        <Typography className={sharedClasses.quote} variant='body1'>
          Fjelstul, Joshua C. The {databaseData.name_with_abbreviation}, 2021. {link("https://www.eulaw.app/databases/" + database.toLowerCase(), "https://www.eulaw.app/databases/" + database.toLowerCase(), sharedClasses)}.
        </Typography>
        <Typography variant="body1">
          The {code("BibTex", sharedClasses)} entry for the database is:
        </Typography>
        <pre className={sharedClasses.codeblock} variant='body1'>
        {
`@Manual{,
  title = {The ` + databaseData.name_with_abbreviation + `},
  author = {Fjelstul, Joshua C.},
  year = {2021},
  url = {https://www.eulaw.app/databases/` + database.toLowerCase() + `},
}`
        }
        </pre>
        <Typography variant="body1">
          If you're going to be using {code("R", sharedClasses)} to work with the data, the {code(dataset, sharedClasses)} dataset is included in the {code(database.toLowerCase(), sharedClasses)} data package, which is available to download on {link("GitHub", "https://github.com/jfjelstul/" + database.toLowerCase(), sharedClasses)}. You can call the data by running {code(database.toLowerCase() + "::" + dataset, sharedClasses)}. You can learn more <Link className={sharedClasses.link} to={"/resources#" + database.toLowerCase()}>here</Link>. If you use the {code(database.toLowerCase(), sharedClasses)} package, please use the following citation:
        </Typography>
        <Typography className={sharedClasses.quote} variant='body1'>
          Joshua C. Fjelstul (2021). {database.toLowerCase()}: The {databaseData.name_with_abbreviation}. R package version 0.1.0.9000 {link("https://github.com/jfjelstul/" + database.toLowerCase(), "https://github.com/jfjelstul/" + database.toLowerCase(), sharedClasses)}.
        </Typography>
        <Typography variant="body1">
          If you're also going to be using data from other <Link className={sharedClasses.link} to={"/"}>eulaw.app</Link> databases, you can access all datasets in all <Link className={sharedClasses.link} to={"/"}>eulaw.app</Link> databases using the {code("eulaw", sharedClasses)} package, which is a convenient, easy-to-use interface to the <Link className={sharedClasses.link} to={"/"}>eulaw.app</Link> API. You can learn more <Link className={sharedClasses.link} to={"/resources#eulaw"}>here</Link>. The {code("eulaw", sharedClasses)} package is available to download on {link("GitHub", "https://github.com/jfjelstul/" + database.toLowerCase(), sharedClasses)}. If you use the {code("eulaw", sharedClasses)} package, please use the following citation:
        </Typography>
        <Typography className={sharedClasses.quote} variant='body1'>
          Joshua C. Fjelstul (2021). eulaw: An R Interface to the eulaw.app API. R package version 0.1.0.9000 {link("https://github.com/jfjelstul/eulaw", "https://github.com/jfjelstul/" + database.toLowerCase(), sharedClasses)}.
        </Typography>
        <div style={{height: 32}}/>
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            {/* <Button label="Download as RData" onClick={() => null}/> */}
            <Button label="Download as RData" filePath={rDataPath} fileName={dataset + ".RData"}/>
          </div>
          <div className={sharedClasses.buttonContainerCentered}>
            {/* <Button label="Download as CSV" onClick={() => null}/> */}
            <Button label="Download as CSV" filePath={csvDataPath} fileName={dataset + ".csv"}/>
          </div>
        </div>
      </div>
    </Drawer>
  }


  var codebookDrawer = null;
  if (datasetsData !== null && databaseData !== null) {
    codebookDrawer =
    <Drawer
      variant="temporary"
      anchor={"top"}
      open={codebookDrawerIsOpen}
      elevation={0}
      classes={{paper: classes.codebookDrawer}}
      transitionDuration={500}
      onClose={() => setCodebookDrawerIsOpen(false)}
      BackdropProps={{
        className: sharedClasses.drawerBackdrop
      }}>
      <div className={classes.closeButtonTop}>
        <IconButton className={classes.icon} disableRipple={true} onClick={() => setCodebookDrawerIsOpen(false)}>
          <CloseIcon/>
        </IconButton>
      </div>
      <div className={sharedClasses.block} style={{backgroundColor: "white"}}>
        <Typography variant="h4">
          Codebook
        </Typography>
        <Typography variant="body1">
          This is the codebook for the {code(dataset, sharedClasses)} dataset in the {databaseData.abbreviation} Database. You can download this codebook as a  {code(".pdf", sharedClasses)} file or as a {code(".csv", sharedClasses)} file.
        </Typography>
        <Typography variant="body1">
          If you have questions about how a variable is coded, please look at the replication materials for the {code(dataset, sharedClasses)} dataset. The replication code is written in {code("R", sharedClasses)} and is included in the {link("GitHub repository", "https://github.com/jfjelstul/" + databaseData.code, sharedClasses)} for the {code("R", sharedClasses)} package {code(databaseData.code, sharedClasses)}, which you can use to access data from the {databaseData.abbreviation} Database. You can also email any questions you have to {link("joshua.fjelstul@unige.ch", "mailto:joshua.fjelstul@unige.ch", sharedClasses)}.
        </Typography>
        {/* <div style={{height: 22}}/> */}
        <div className={sharedClasses.blockRowCentered}>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="Download as PDF" filePath={pdfCodebookPath} fileName={database + "_codebook.pdf"}/>
            {/* <Button label="Download as PDF" onClick={() => null}/> */}
            {/* <Button label="Download as PDF" onClick={() => downloadCodebook("https://www.api.eulaw.app/databases/" + database + "/variables?dataset=" + dataset, dataset + "_codebook.pdf")}/> */}
          </div>
          <div className={sharedClasses.buttonContainerCentered}>
            <Button label="Download as CSV" filePath={csvCodebookPath} fileName={database + "_codebook.csv"}/>
            {/* <Button label="Download as CSV" onClick={() => null}/> */}
            {/* <Button label="Download as CSV" onClick={() => downloadCodebook("https://www.api.eulaw.app/databases/" + database + "/variables?dataset=" + dataset, dataset + "_codebook.csv")}/> */}
          </div>
        </div>
        <div style={{ height: 50 }}/>
        <DataCodebook url={"https://www.api.eulaw.app/databases/" + database + "/variables?dataset=" + dataset} />
      </div>
    </Drawer>
  }

  var descriptions = null;
  if(datasetsData !== null && databaseData !== null) {
    descriptions = datasetsData.map((row, i) => (
      <div className={sharedClasses.cardContainer}>
        <Card className={sharedClasses.card}>
          <CardMedia image={image} style={{height: 150}} className={sharedClasses.cardImage}/>
          <div className={sharedClasses.cardContent}>
            <div className={sharedClasses.cardTitle}>
              {row.label}
            </div>
            {/* <Typography variant="body1">
              {code(row.dataset, sharedClasses)}
            </Typography> */}
            <Typography variant="body1">
              The {code(row.dataset, sharedClasses)} {row.description.replace("This", "")}
            </Typography>
            {/* <Typography variant="body1">
              The {code(row.dataset, sharedClasses)} dataset is available as an {code(".RData", sharedClasses)} file or as a {code(".csv", sharedClasses)} file. The codebook is available as a  {code(".pdf", sharedClasses)} file or as a {code(".csv", sharedClasses)} file.
            </Typography> */}
            <div className={sharedClasses.blockRowCentered}>
              <div className={sharedClasses.buttonContainer}>
                <Button label="Download Data" onClick={() => handleDataDrawer(row.dataset, "/data/r/" + database + "/" + row.dataset + ".RData", "/data/csv/" + database + "/" + database + "_" + row.dataset + ".csv")}/>
              </div>
              <div className={sharedClasses.buttonContainer}>
                <Button label="View Codebook" onClick={() => handleCodebookDrawer(row.dataset, "/codebooks/pdf/" + database + "_codebook.pdf", "/codebooks/csv/" + database + "_codebook.csv")}/>
              </div>
            </div>
          </div>
        </Card>
      </div>
    ));
  }

  var content = <div style={{ height: 1000 }}/>;
  if (datasetsData !== null && databaseData !== null) {
    content =
    <div id="overview">
      <ParallaxBlock path={image} content={landing("The " + databaseData.abbreviation + " Database", "Explore the " + datasetsData.length + " datasets of the " + databaseData.abbreviation + " database")}/>
      <div className={sharedClasses.cardPageHeader}>
        <div className={sharedClasses.block}>
          <Typography variant="h4">
            The {databaseData.name_with_abbreviation}
          </Typography>
          <Typography variant="body1">
            {databaseData.long_description}
          </Typography>
          {/* <div className={classes.chipBar}>
            {datasetsData.map((row, i) => (
              <div className={classes.chipContainer}>
                <Chip className={classes.chip} key={i} clickable label={row.dataset} onClick={() => {scroll(row.dataset, -75)}}/>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className={sharedClasses.cardPage}>
        {descriptions}
      </div>
      <Footer shadow={true}/>
    </div>
  }

  return (
    <div className={sharedClasses.page}>
      {/* <Sidebar data={sidebarData} /> */}
      {/* <Content content={content}/> */}
      {content}
      {dataDrawer}
      {codebookDrawer}
    </div>
  );
};
