// React components
import React, { useEffect } from "react"
import { Link } from "react-router-dom"

// Material-UI components
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from '@material-ui/core/useMediaQuery';

// styles
import { useTheme, createUseStyles } from "react-jss";
import useSharedStyles from "./SharedStyles";

const useStyles = createUseStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    textAlign: "center",
    backgroundColor: "blue",
    height: 100,
  },
}));

const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default function ResponsivePage (props) {

  const sharedClasses = useSharedStyles();
  const classes = useStyles;

  const matchesxs = useMediaQuery('(min-width: 0px)');
  const matchessm = useMediaQuery('(min-width: 600px)');
  const matchesmd = useMediaQuery('(min-width: 960px)');
  const matcheslg = useMediaQuery('(min-width: 1280px)');

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div className={classes.root}>
        <div style={{height: 300}}/>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className={classes.paper}>xs=12</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>xs=12 sm=6</div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.paper}>xs=12 sm=6</div>
          </Grid>
          {/* <Grid item xs={6} sm={3}>
            <div className={classes.paper}>xs=6 sm=3</div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div className={classes.paper}>xs=6 sm=3</div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div className={classes.paper}>xs=6 sm=3</div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <div className={classes.paper}>xs=6 sm=3</div>
          </Grid> */}
        </Grid>
        <div>{`extra small: ${matchesxs}`}</div>
        <div>{`small: ${matchessm}`}</div>
        <div>{`medium: ${matchesmd}`}</div>
        <div>{`large: ${matcheslg}`}</div>
        <div style={{height: 300}}/>
      </div>
    </MuiThemeProvider>
  );
};
