// React components
import React from 'react';

// Material-UI components
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  progress: {
    color: theme.primaryColor + "!important",
  },
}));

export default function Progress (props) {

  const classes = useStyles();

  return(
    <div>
      <CircularProgress />
    </div>
  );
};
