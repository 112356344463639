// react components
import React, { Component } from "react";
import { useState } from "react";
import { Route, Link, Switch, useLocation } from "react-router-dom";

// material-ui components
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";

// material-ui styles
import { withStyles, makeStyles} from "@material-ui/core/styles";

// components
import Footer from "./Footer";

// pages
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import DatabasesPage from "./DatabasesPage";
import DatasetsPage from "./DatasetsPage";
import ResourcesPage from "./ResourcesPage";
import ResearchPage from "./ResearchPage";
import Responsive from "./Responsive";

// utilities
import ScrollToTop from "./ScrollToTop";
import SmoothScroll from "smoothscroll-polyfill";

// styles
import useSharedStyles from "./SharedStyles";

SmoothScroll.polyfill();

const CustomDrawer = withStyles((theme) => ({

}))(Drawer);

const CustomAppBar = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    height: theme.appBarHeightMobile,
    backgroundColor: theme.palette.common.white,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1) !important",
    [theme.breakpoints.up("md")]: {
      height: theme.appBarHeight,
    },
  }
}))(AppBar);

const CustomTab = withStyles((theme) => ({
  root: {
    // textTransform: "uppercase",
    // letterSpacing: 3,
    fontSize: 16,
    height: 50,
    minWidth: 72,
    marginLeft: 12,
    marginRight: 12,
    paddingRight: 12,
    paddingLeft: 12,
    opacity: 0.7,
    color: theme.palette.custom.primary,
    '&:hover': {
      color: theme.palette.custom.primary,
      opacity: 1,
    },
    [theme.breakpoints.up("md")]: {
      height: 75,
      marginLeft: 24,
      marginRight: 24,
      paddingRight: 24,
      paddingLeft: 24,
    },
  },
  wrapper: {
    fontFamily: "Roboto Condensed",
    fontWeight: 400,
  },
}))(Tab);

const CustomTabs = withStyles((theme) => ({
  root: {
    height: 50,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 275,
      height: 75,
    },
  }
}))(Tabs);

const useStyles = makeStyles((theme) => ({
  appBarBranding: {
    position: "fixed",
    height: 75,
    width: "100%",
    left: 0,
    top: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.drawer + 10,
    [theme.breakpoints.up("md")]: {
      width: 275,
    },
  },
}));

function SetTabIndicator () {
  const location = useLocation();
  if (location.pathname.includes("databases")) {
    return(1)
  } else if (location.pathname.includes("resources")) {
    return(2)
  } else if (location.pathname.includes("research")) {
    return(3)
  } else if (location.pathname.includes("about")) {
    return(4)
  } else {
    return(0)
  }
}

export default function App (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [shadow, setShadow] = useState("0px 5px 30px 0px rgba(0, 0, 0, 0)");
  const [menuOpen, setMenuOpen] = useState(false);
  // const [tab, setTab] = useState(SetTabIndicator());

  const updateAppBar = (newShadow) => {
    setShadow(newShadow);
  }

  function ElevationScroll(props) {
    const { children } = props;
    var trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 200,
    });
    return React.cloneElement(children, {
      style: trigger ? {boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)"} : {boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0)"},
    });
  };


  // const tabs =
  // <div className={classes.appBarContainer}>
  //   <div className={classes.appBarTab}>
  //     <Link className={IsSelected("databases") ? sharedClasses.buttonSelected : sharedClasses.button} to={"/databases"}>Databases</Link>
  //   </div>
  //   <div className={classes.appBarTab}>
  //     <Link className={IsSelected("resources") ? sharedClasses.buttonSelected : sharedClasses.button} to={"/resources"}>Resources</Link>
  //   </div>
  //   <div className={classes.appBarTab}>
  //     <Link className={IsSelected("research") ? sharedClasses.buttonSelected : sharedClasses.button} to={"/research"}>Research</Link>
  //   </div>
  //   <div className={classes.appBarTab}>
  //     <Link className={IsSelected("about") ? sharedClasses.buttonSelected : sharedClasses.button} to={"/about"}>About</Link>
  //   </div>
  // </div>;

  const appBar =
  <CustomAppBar position="sticky">
    <CustomTabs
      value={SetTabIndicator()}
      variant="scrollable"
      scrollButtons="on"
      TabIndicatorProps={{
        style: {
          height: 4,
        }
      }}
    >
      <CustomTab label="Home" component={Link} to="/"/>
      <CustomTab label="Databases" component={Link} to="/databases"/>
      <CustomTab label="Resources" component={Link} to="/resources"/>
      <CustomTab label="Research" component={Link} to="/research"/>
      <CustomTab label="About" component={Link} to="/about"/>
    </CustomTabs>
  </CustomAppBar>

  const menu =
  <Drawer
    anchor="top"
    style={{zIndez: 1}}
    open={menuOpen}
    elevation={0}
    transitionDuration={500}
    onClose={() => {setMenuOpen(false)}}
    BackdropProps={{ className: classes.menuDrawerBackdrop }}>
    <List>
      <ListItem button component={Link} to="/" onClick={() => {setMenuOpen(false)}}>
        <ListItemText>Home</ListItemText>
      </ListItem>
      <Divider/>
      <ListItem button component={Link} to="/databases" onClick={() => {setMenuOpen(false)}}>
        <ListItemText>Databases</ListItemText>
      </ListItem>
      <ListItem button component={Link} to="/resources" onClick={() => {setMenuOpen(false)}}>
        <ListItemText>Resources</ListItemText>
      </ListItem>
      <ListItem button component={Link} to="/research" onClick={() => {setMenuOpen(false)}}>
        <ListItemText>Research</ListItemText>
      </ListItem>
      <ListItem button component={Link} to="/about" onClick={() => {setMenuOpen(false)}}>
        <ListItemText>About</ListItemText>
      </ListItem>
    </List>
  </Drawer>

  var logo =
  <div className={classes.appBarBranding}>
    <Link className={classes.link} to={"/"}>
      <img src="/logo.png" style={{height: 75, paddingTop: 5}}/>
    </Link>
  </div>

  return (
    <div>
      {appBar}
      {logo}
      <Switch>
        <Route path="/" exact component={HomePage}/>
        <Route path="/databases" exact component={DatabasesPage}/>
        <Route path="/databases/evoeu" exact render={() => <DatasetsPage database="evoeu" image="/background-10.jpg"/>}/>
        <Route path="/databases/ecio" exact render={() => <DatasetsPage database="ecio" image="/background-18.jpg"/>}/>
        <Route path="/databases/euip" exact render={() => <DatasetsPage database="euip" image="/background-7.jpg"/>}/>
        <Route path="/databases/eusa" exact render={() => <DatasetsPage database="eusa" image="/background-8.jpg"/>}/>
        <Route path="/databases/eutr" exact render={() => <DatasetsPage database="eutr" image="/background-15.jpg"/>}/>
        <Route path="/databases/eums" exact render={() => <DatasetsPage database="eums" image="/background-19.jpg"/>}/>
        <Route path="/resources" exact component={ResourcesPage}/>
        <Route path="/research" exact component={ResearchPage}/>
        <Route path="/about" exact component={AboutPage}/>
      </Switch>
    </div>
  );
};
