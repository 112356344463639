// React components
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

// Material-UI components
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import WebsiteIcon from "@material-ui/icons/AccountCircle";
import Grid from "@material-ui/core/Grid";

// styles
// import { ThemeProvider, withTheme } from "react-jss";
// import { useTheme, createUseStyles } from "react-jss";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from "./SharedStyles";
import theme from "./theme";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "relative",
    backgroundColor: theme.palette.custom.primary,
    // zIndex: theme.zIndex.drawer + 1,
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: `calc(100v - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    // },
    width: "100%",
    // borderTopStyle: "solid",
    // borderWidth: 1,
    // borderColor: "#E8E8E8",
  },
  footerTopContent: {
    paddingTop: 25,
    // paddingBottom: 25,
    paddingLeft: 20,
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingLeft: 0,
    }
  },
  footerMiddleContent: {
    paddingTop: 25,
    // paddingBottom: 25,
    flexDirection: "column",
    justifyContent: "center",
  },
  footerBottomContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 35,
    paddingBottom: 50,
  },
  icon: {
    color: "#FFFFFF !important",
    marginRight: "15px !important",
    padding: "15px !important",
  },
  copyright: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "300",
    paddingLeft: 20,
    opacity: 0.8,
    [theme.breakpoints.up("md")]: {
      // paddingLeft: 50,
      width: "100%",
      textAlign: "center",
      paddingLeft: 0,
    },
  },
  social: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  footerColumns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  footerColumn: {
    margin: "0px 20px 0px 20px",
    [theme.breakpoints.up("md")]: {
      margin: "0px 30px 0px 30px",
    },
  },
  footerHeading: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 16,
    fontFamily: "Roboto Condensed",
    // textTransform: "uppercase",
    // letterSpacing: 3,
  },
  footerItem: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: 300,
    marginTop: 12,
    marginBottom: 12,
    lineHeight: 1.5,
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
    },
  },
  footerBreak: {
    height: 23,
  },
  branding: {
    color: "#FFFFFF!important",
    fontSize: 28,
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  footerBar: {
    position: "relative",
    backgroundColor: "#F2F2F2",
    height: 50,
    zIndex: theme.zIndex.drawer + 1,
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default function Footer (props) {

  const { shadow } = props;

  const classes = useStyles();
  const sharedClases = useSharedStyles();

  // const social =
  // <div className={classes.social}>
  //   <IconButton className={classes.icon} disableRipple={true} href={"https://www.joshuafjelstul.com"}>
  //     <WebsiteIcon/>
  //   </IconButton>
  //   <IconButton className={classes.icon} disableRipple={true} href={"https://www.linkedin.com/in/jfjelstul"}>
  //     <LinkedInIcon/>
  //   </IconButton>
  //   <IconButton className={classes.icon} disableRipple={true} href={"https://www.github.com/jfjelstul"}>
  //     <GitHubIcon/>
  //   </IconButton>
  // </div>;

  const footerColumns =
  <div className={classes.footerColumns}>
    {/* <div className={classes.footerColumn}>
      <Link className={classes.branding} to={"/"}>eulaw.app</Link>
      <div className={classes.footerBreak}></div>
    </div> */}
    <div className={classes.footerColumn}>
      <div className={classes.footerHeading}>Databases</div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/evoeu"}>The Evolution of European Union Law (EvoEU) Database</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/ecio"}>The European Commission Internal Organization (ECIO) Database</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/euip"}>The European Union Infringement Procedure (EUIP) Database</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/eusa"}>The European Union State Aid (EUSA) Database</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/eutr"}>The European Union Technical Regulations (EUTR) Database</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/databases/eums"}>The European Union Member States (EUMS) Database</Link>
      </div>
      <div className={classes.footerBreak}></div>
    </div>
    <div className={classes.footerColumn}>
      <div className={classes.footerHeading}>Resources</div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/resources#r-packages"}>R packages</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/resources#eulaw-app-api"}>The eulaw.app API</Link>
      </div>
      <div className={classes.footerBreak}></div>
      <div className={classes.footerHeading}>Research</div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/research#publications"}>Publications</Link>
      </div>
      <div className={classes.footerItem}>
        <Link className={classes.link} to={"/research#working-papers"}>Working Papers</Link>
      </div>
    </div>
  </div>;

  return (
    <div className={classes.footer} style={{boxShadow: shadow ? "0px 0px 30px 0px rgba(0, 0, 0, 0.15)" : "none"}}>
      <div className={classes.footerTopContent}>
        <Link className={classes.link} to={"/"}>
          <img src="/logo-white.png" style={{height: 85}}/>
        </Link>
      </div>
      <div className={classes.footerMiddleContent}>
        {footerColumns}
      </div>
      <div className={classes.footerBottomContent}>
        <div className={classes.copyright}>
          © 2021 Joshua C. Fjelstul, Ph.D.
        </div>
      </div>
    </div>
  );
}
