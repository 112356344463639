// React components
import React, { useEffect } from "react"

// API components
import Axios from "axios";

// material-ui components
import Typography from "@material-ui/core/Typography";

// components
import Progress from "./Progress";

// styles
import useSharedStyles from "./SharedStyles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "80%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "15%",
      marginRight: "15%",
    },
    transition: "opacity 0.5s",
    transitionDelay: "0.5s",
    overflowX: "scroll",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderColor: theme.palette.custom.line,
    // borderRadius: 8,
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  headerCell: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 16,
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.custom.primary,
    // backgroundColor: theme.palette.custom.codeBackground,
  },
  cell:  {
    fontSize: 16,
    fontWeight: 300,
    paddingRight: 32,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
    lineHeight: 1.8,
    border: "none",
    verticalAlign: "top",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
    // borderTopStyle: "solid",
    // borderWidth: 1,
    // borderColor: theme.palette.custom.line,
  },
  row: {
    // opacity: 0.7,
    // '&:hover': {
    //   opacity: 1,
    // },
  },
}));

function GetCodebookData (url) {
  const [data, setData] = React.useState(null);
  useEffect(() => {
    Axios.get(url)
    .then(response => {
      setData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [url]);
  return data;
};

function CustomTable (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { data } = props;

  const [color, setColor] = React.useState("#FFFFFF");

  const changeColor = (newColor) => {
    setColor(newColor)
  };

  const capitalize = (x) => {
    if (typeof x !== 'string') {
      return '';
    }
    return x.charAt(0).toUpperCase() + x.slice(1);
  }

  const parseVariableCell = (text) => {
    return <span className={sharedClasses.code}>{text}</span>;
  }

  const parseDescriptionCell = (text) => {
    var snippet = null;
    // var text = "{" + type + "} " + text
    text = text.split(/{|}/);
    var out = text.map((snippet, i) => {
      if(i % 2 == 0) {
        return(<span>{snippet}</span>);
      } else {
        return(<span className={sharedClasses.code}>{snippet}</span>);
      }
    });
    return out;
  };

  // <table className={classes.table}>
  //   <thead>
  //     <tr key={1} className={classes.headerRow}>
  //       <td className={classes.headerCell}>
  //         Variable
  //       </td>
  //       <td className={classes.headerCell}>
  //         Description
  //       </td>
  //     </tr>
  //   </thead>
  //   <tbody>
  //     {data.map((row, i) => (
  //       <tr key={i} className={classes.row}>
  //           <td className={classes.cell}>
  //             {parseVariableCell(row.variable)}
  //           </td>
  //           <td className={classes.cell}>
  //             {parseDescriptionCell(row.type, row.description)}
  //           </td>
  //       </tr>
  //     ))}
  //   </tbody>
  // </table>

  return(
    <div>
      {data.map((row, i) => (
        <div>
          <Typography variant="body1">
            {parseVariableCell(row.variable)}
          </Typography>
          <Typography variant="body1" style={{paddingLeft: 32}}>
            {parseDescriptionCell(row.description)}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default function DataCodebook(props) {

  const classes = useStyles();

  const { url } = props;

  var table = null;

  const data = GetCodebookData(url);

  if (data != null) {
    table = <CustomTable data={data} />;
  }

  return (
    <div className={classes.container} style={{ opacity: data == null ? 0 : 1 }}>
      {table}
    </div>
  );
};
